import {jsonLDConfigs} from '../content/trt-seo-json-ld'

export const setJSONLD = async(state) => {
  let currentRoute = state.location.pathname
    if (currentRoute !== "/") {
        currentRoute = currentRoute.replace(/\/$/, "");
    }
    const head = document.getElementsByTagName('head')[0];
    const hreflangElement = document.createElement('link');
        hreflangElement.rel = 'alternate';
        hreflangElement.hreflang = 'en-us';
        hreflangElement.href = state.location.href.replace(/\/$/, "")
        head.appendChild(hreflangElement)

    const seoConfig = jsonLDConfigs.find(config => config.url[0].includes(currentRoute));
      if (!seoConfig) {
        return false;
      }
        const scriptElement = document.createElement('script');
        scriptElement.type = 'application/ld+json';
        scriptElement.setAttribute('data-info', 'json-ld');
        scriptElement.innerHTML = seoConfig.value;
        const previousScript = head.querySelectorAll(`[data-info='json-ld']`)[0];
        if (previousScript) {
          head.removeChild(previousScript);
        }
        head.appendChild(scriptElement);
  
  }