/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReactGA from 'react-ga'
import { jsonLDConfigs } from './src/content/trt-seo-json-ld'
import { setJSONLD } from './src/components/set-json-ld'

ReactGA.initialize(
  [
    {
      trackingId: process.env.GTM_TRACKING_ID,
      gaOptions: { name: 'gatsby-gdpr-google-tagmanager' },
    },
  ],
  {
    debug: false,
  }
)

export const onRouteUpdate = async (state) => {
  ReactGA.set({ page: state.location.pathname })
  ReactGA.pageview(state.location.pathname)
  ReactGA.set({ anonymizeIp: 1 })

  // Setting json-ld data
  await setJSONLD(state)
}

export const onServiceWorkerUpdateReady = () => {
  console.log(
    '%c --- onServiceWorkerUpdateReady: Code has been updated.',
    'color: blue'
  )
}

export const onClientEntry = () => {
  window.onload = () => {
    const scripts = [
      'https://www.googletagmanager.com/ns.html?id=GTM-5XZJQBJ'
    ]
    
    scripts.forEach(src => {
      const script = document.createElement('script')
      script.src = src
      document.body.appendChild(script)
    })
    
    // You can also run other code here
    console.log('Page has finished loading!')



    //Appending GTM Link
    function appendGTM(id) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${id}`;
      
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
      
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    }
    
    // Call the function with your GTM ID
    appendGTM('GTM-5XZJQBJ');


    //Appending Font Link
    function appendFontLink() {
      const link = document.createElement('link');
      link.as = 'style';
      link.rel = 'stylesheet preload prefetch';
      link.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap';
      link.type = 'text/css';
      link.crossOrigin = 'anonymous';
    
      document.head.appendChild(link);
    }
    
    // Call the function to append the link
    appendFontLink();
  }
}

// import './src/css/bootstrap/scss/bootstrap.scss';
import 'bootstrap/scss/bootstrap.scss'
import './src/css/variables.scss'
import './src/css/main-content.scss'
import './src/css/responsive.scss'
